import './App.css'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { BrowserRouter as Router } from 'react-router-dom'

import Routes from './navigations/Routes'
import { ENDPOINT_URL } from './utilities/endpoints'
//Components
import ScrollToTop from './components/ScrollToTop'
import Header from './components/Header'
import Footer from './components/Footer'

const client = new ApolloClient({
    uri: ENDPOINT_URL,
    cache: new InMemoryCache(),
})

function App() {
    return (
        <ApolloProvider client={client}>
            <Router>
                <ScrollToTop />
                <Header />
                <Routes />
                <Footer />
            </Router>
        </ApolloProvider>
    )
}

export default App
