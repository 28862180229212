import { Outlet } from 'react-router-dom'

const AdminRoute = ({ ...rest }) => {
    // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    // const roleId = useSelector((state) => state.user.roleId)
    // const auth = null

    // return auth ? <Outlet /> : <Navigate to="/login" />
    return <Outlet />
} //AdminRoute

export default AdminRoute
