import React from 'react'

const SocialMediaItem = ({ image, title = '', url, color }) => {
    return (
        <a
            href={url}
            className="social-media-item"
            target="_blank"
            rel="noopener noreferrer"
            style={{ backgroundColor: color }}
        >
            <img src={image} alt={title} className="social-media-icon" />
        </a>
    )
}

export default SocialMediaItem
