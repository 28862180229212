import React from 'react'
import parseHtml from 'html-react-parser'
//Data
import references from '../assets/translations/es/references.json'

const References = () => {
    return (
        <div className="section-convida">
            <div className="container" style={{ backgroundColor: '#FFF' }}>
                <p className="header-condition-terms-of-service mt-20">
                    {references.title}
                </p>
                <p className="description-condition-terms-of-service mt-10">
                    {parseHtml(references.description)}
                </p>
                {references.references.map((reference, index) => (
                    <div key={index}>
                        {reference.descriptions.map(
                            (currentDescription, index) => (
                                <p
                                    key={index}
                                    className="description-condition-terms-of-service mt-10"
                                >
                                    {parseHtml(currentDescription.description)}
                                </p>
                            )
                        )}
                    </div>
                ))}
                <p className="description-condition-terms-of-service mt-10">
                    {parseHtml(references.additionalInfo)}
                </p>
            </div>
        </div>
    )
}

export default References
