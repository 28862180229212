import React, { useState } from 'react'
import { Link } from 'react-router-dom'
//Images
import Logo from '../assets/images/logo.png'
//Utils
import { redirectStoresDowloadApp } from '../utilities/general'

const Header = () => {
    const [isActiveNavBar, setIsActiveNavBar] = useState(false)

    const handleToggleNavBar = () => {
        setIsActiveNavBar(!isActiveNavBar)
    }

    const handleCloseNavBar = () => {
        isActiveNavBar && setIsActiveNavBar(!isActiveNavBar)
    }

    return (
        <nav className="navbar is-fixed-top is-transparent">
            <div className="container">
                <div className="navbar-brand">
                    <Link className="navbar-item" to="/">
                        <img
                            src={Logo}
                            alt="Logo ConVida"
                            width="112"
                            height="28"
                        />
                    </Link>
                    <button
                        className={`navbar-burger ${
                            isActiveNavBar && 'is-active'
                        }`}
                        onClick={handleToggleNavBar}
                    >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </button>
                </div>

                <div
                    id="navbarExampleTransparentExample"
                    className={`navbar-menu ${isActiveNavBar && 'is-active'}`}
                >
                    <div className="navbar-start"></div>

                    <div className="navbar-end">
                        <Link
                            className="navbar-item"
                            to="/#nutrition"
                            onClick={handleCloseNavBar}
                        >
                            Nutrición
                        </Link>
                        <Link
                            className="navbar-item"
                            to="/#physical-activity"
                            onClick={handleCloseNavBar}
                        >
                            Actividad Física
                        </Link>
                        <Link
                            className="navbar-item"
                            to="/#emotional-health"
                            onClick={handleCloseNavBar}
                        >
                            Salud Emocional
                        </Link>
                        <div className="navbar-item">
                            <div className="field is-grouped">
                                <p className="control">
                                    <a
                                        className="button is-primary-button-cv"
                                        onClick={redirectStoresDowloadApp}
                                        href="/"
                                    >
                                        <span>Descargar APP</span>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
} //Header

export default Header
