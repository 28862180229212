import React, { useState } from 'react'
import useCollapse from 'react-collapsed'

//Images
import GreenOpenIcon from '../assets/images/green-open-icon.png'
import RedCloseIcon from '../assets/images/red-close-icon.png'

const FAQToggle = ({ index, title, description }) => {
    const [isExpanded, setExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({
        isExpanded,
        easing: 'cubic-bezier(0.42, 0, 0.58, 1.0)',
        duration: 300,
    })

    const key = ++index
    const bgColor = key % 2 === 0 ? 'grey-toggle-item' : 'white-toggle-item'

    return (
        <div className="faq-container">
            <button
                className={`button toggle-item ${bgColor}`}
                {...getToggleProps({
                    onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                })}
            >
                <div className="header-faq-container">
                    <p className="faq-question">{`${key}. ${title}`}</p>
                    <img
                        src={isExpanded ? RedCloseIcon : GreenOpenIcon}
                        alt="Icon faq"
                        className="faq-icon"
                    />
                </div>
            </button>
            <div {...getCollapseProps()}>
                <div className={`content-faq-container ${bgColor}`}>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    )
}

export default FAQToggle
