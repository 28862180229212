import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { READ_DIET_PLAN } from '../api/Diet'
import { useForm } from '../hooks/useForm'

export const DietsDistributions = () => {
    const [dietPlan, setDietPlan] = useState({
        amountDairy: 0,
        amountFats: 0,
        amountFlours: 0,
        amountFruits: 0,
        amountProteins: 0,
        amountVegetables: 0,
        gramsCarbohydrates: 0,
        gramsFats: 0,
        gramsProteins: 0,
    })

    const [values, handleInputChange] = useForm({
        energyCalculation: '',
        dietType: 'ECONOMIC',
    })
    const { energyCalculation, dietType } = values

    const regexp = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/

    const handleSubmit = (e) => {
        e.preventDefault()

        const tempEnergyCalculation = Number(energyCalculation)
        getPlan({
            variables: {
                readDietPlanInput: {
                    diet: dietType,
                    energyCalculation: tempEnergyCalculation,
                },
            },
        })
    }

    const [getPlan] = useLazyQuery(READ_DIET_PLAN, {
        fetchPolicy: 'no-cache',
        onCompleted: ({ readDietPlan }) => {
            setDietPlan({ ...dietPlan, ...readDietPlan })
        },
        onError: (error) => {
            alert(error)
            //console.log(error.message)
        },
    })

    const handleCheckEnergyValue = (event) => {
        const value = event.target.value
        if (value === '' || regexp.test(value)) {
            handleInputChange(event)
        }
    }

    return (
        <div className="container is-fullhd p-5">
            <div className="container m-5">
                <div className="section-form my-5">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="columns">
                            <div className="column">
                                <label className="label">
                                    Ingrese el cálculo energético
                                </label>
                                <input
                                    id="energyCalculation"
                                    name="energyCalculation"
                                    className="input"
                                    type="text"
                                    placeholder="Ingrese el cálculo energético"
                                    required
                                    value={energyCalculation}
                                    onChange={handleCheckEnergyValue}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="column">
                                <label className="label">
                                    Seleccione la dieta
                                </label>
                                <div className="select is-fullwidth">
                                    <select
                                        id="dietType"
                                        name="dietType"
                                        value={dietType}
                                        onChange={handleInputChange}
                                    >
                                        <option value="ECONOMIC">
                                            Dieta económica
                                        </option>
                                        <option value="PROTEINS">
                                            Dieta preferencia por proteína
                                        </option>
                                        <option value="LOWCARB">
                                            Dieta Low Carb
                                        </option>
                                        <option value="KETO">Dieta Keto</option>
                                        <option value="VEGETARIAN">
                                            Dieta vegetariana
                                        </option>
                                        <option value="VEGAN">
                                            Dieta vegana
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="column container-button-end">
                                <label className="label">&nbsp;</label>
                                <button className="button is-fullwidth is-dark">
                                    Calcular
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="section-results">
                    <h6 className="mb-3 title">Resultados</h6>
                    <table className="table is-bordered is-narrow is-hoverable">
                        <tbody>
                            <tr>
                                <th className="tb-data">
                                    <p className="tb-data-title">
                                        Gramos de carbohidratos
                                    </p>
                                    <p>{dietPlan.gramsCarbohydrates}</p>
                                </th>
                            </tr>
                            <tr>
                                <th className="tb-data">
                                    <p className="tb-data-title">
                                        Gramos de proteínas
                                    </p>
                                    <p>{dietPlan.gramsProteins}</p>
                                </th>
                            </tr>
                            <tr>
                                <th className="tb-data">
                                    <p className="tb-data-title">
                                        Gramos de grasas
                                    </p>
                                    <p>{dietPlan.gramsFats}</p>
                                </th>
                            </tr>
                            <tr>
                                <th className="tb-data">
                                    <p className="tb-data-title">
                                        Porciones de lácteos
                                    </p>
                                    <p>{dietPlan.amountDairy}</p>
                                </th>
                            </tr>
                            <tr>
                                <th className="tb-data">
                                    <p className="tb-data-title">
                                        Porciones de frutas
                                    </p>
                                    <p>{dietPlan.amountFruits}</p>
                                </th>
                            </tr>
                            <tr>
                                <th className="tb-data">
                                    <p className="tb-data-title">
                                        Porciones de vegetales
                                    </p>
                                    <p>{dietPlan.amountVegetables}</p>
                                </th>
                            </tr>
                            <tr>
                                <th className="tb-data">
                                    <p className="tb-data-title">
                                        Porciones de harinas
                                    </p>
                                    <p>{dietPlan.amountFlours}</p>
                                </th>
                            </tr>
                            <tr>
                                <th className="tb-data">
                                    <p className="tb-data-title">
                                        Porciones de proteínas
                                    </p>
                                    <p>{dietPlan.amountProteins}</p>
                                </th>
                            </tr>
                            <tr>
                                <th className="tb-data">
                                    <p className="tb-data-title">
                                        Porciones de grasas
                                    </p>
                                    <p>{dietPlan.amountFats}</p>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
