import React from 'react'

const VideoPhoneMask = ({ video }) => {
    return (
        <div className="phone-preview">
            <div className="phone-video-mask"></div>
            <div className="phone-video">
                <video src={video} autoPlay loop muted playsInline />
            </div>
        </div>
    )
}

export default VideoPhoneMask
