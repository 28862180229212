import React, { useEffect } from 'react'
import { Route, Routes as Switch, useLocation } from 'react-router-dom'
//Private Screens
// import PublicRoute from './PublicRoute'
import AdminRoute from './AdminRoute'
// import UserRoute from './UserRoute'
import { DietsDistributions } from '../screens/DietsDistributions'
import DietPlanLogs from '../screens/DietPlanLogs'
import Home from '../screens/Home'
import TermsOfService from '../screens/TermsOfService'
import PrivacyPolicy from '../screens/PrivacyPolicy'
import References from '../screens/References'

const Routes = () => {
    const { pathname, hash, key } = useLocation()

    useEffect(() => {
        if (hash === '') {
            window.scrollTo(0, 0)
        } else {
            setTimeout(() => {
                const id = hash.replace('#', '')
                const element = document.getElementById(id)
                element &&
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    })
            }, 0)
        }
    }, [pathname, hash, key])

    return (
        <Switch>
            {/* ------------------------ Admin Routes ------------------------ */}
            <Route exact path="/" element={<Home />} />

            <Route
                exact
                path="/terms-of-service"
                element={<TermsOfService />}
            />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/references" element={<References />} />

            <Route exact path="/diets-distributions" element={<AdminRoute />}>
                <Route
                    exact
                    path="/diets-distributions"
                    element={<DietsDistributions />}
                />
            </Route>

            <Route path="/diet-plan-logs" exact element={<DietPlanLogs />} />

            {/* ------------------------ User Routes ------------------------ */}
            {/* <UserRoute exact path="/user-home">
                <Home />
            </UserRoute> */}

            {/* ------------------------ Public Routes ------------------------ */}
            {/* <PublicRoute exact path="/sign-up/:membershipTypeId?">
                <SignUp />
            </PublicRoute> */}
        </Switch>
    )
}

export default Routes
