import React from 'react'
import { Link } from 'react-router-dom'
//Images
import VerticalLogo from '../assets/images/vertical-logo.png'
//Components
import SocialMediaItem from './SocialMediaItem'
//Utils
import { SOCIAL_MEDIA } from '../utilities/general'

const Footer = () => {
    return (
        <footer
            className="footer"
            style={{ backgroundColor: '#F6F7F9', padding: 0 }}
        >
            <div
                className="container"
                style={{
                    padding: '9.875rem 0rem',
                }}
            >
                <div className="columns is-vcentered">
                    <div className="column">
                        <div className="columns is-centered">
                            <div className="column">
                                <p className="title-footer">Compañía</p>
                                <p className="mt-10">
                                    <Link
                                        className="option-footer"
                                        to="/terms-of-service"
                                    >
                                        Términos y condiciones
                                    </Link>
                                </p>
                                <p className=" mt-10">
                                    <Link
                                        className="option-footer"
                                        to="/privacy-policy"
                                    >
                                        Política de privacidad
                                    </Link>
                                </p>
                            </div>
                            <div className="column">
                                <p className="title-footer">Recursos</p>
                                <p className=" mt-10">
                                    <Link
                                        className="option-footer"
                                        to="/references"
                                    >
                                        Referencias
                                    </Link>
                                </p>
                            </div>
                            <div className="column">
                                <p className="title-footer">Contacto</p>
                                <p className="option-footer mt-10">
                                    info@convida.fit
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="column center-column">
                        <div className="is-flex is-justify-content-flex-end">
                            <img
                                src={VerticalLogo}
                                style={{
                                    height: 150,
                                    width: 150,
                                    objectFit: 'contain',
                                }}
                                alt="Logo ConVida"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="is-flex"
                style={{
                    backgroundColor: '#FFF',
                    padding: '2rem 0rem',
                }}
            >
                <div className="container">
                    <div className="is-flex is-justify-content-space-between is-align-items-center additional-info-container">
                        <div>ConVida 2023</div>
                        <div className="is-flex is-align-items-center is-flex-direction-row social-media-container">
                            {Object.keys(SOCIAL_MEDIA).map((key, index) => (
                                <SocialMediaItem
                                    key={index}
                                    color={SOCIAL_MEDIA[key].color}
                                    url={SOCIAL_MEDIA[key].url}
                                    image={SOCIAL_MEDIA[key].img}
                                    title={SOCIAL_MEDIA[key].title}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
} //Footer

export default Footer
