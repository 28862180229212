import React from 'react'
import Carousel from 'react-multi-carousel'
//Images
import MobilePhone1 from '../assets/images/mobile-phone-1.png'
//import MobilePhone2 from '../assets/images/mobile-phone-2.png'
import MobilePhone3 from '../assets/images/mobile-phone-3.png'
import ConvidaTeamIcon from '../assets/images/convida-team-icon.png'
import AppStoreDowloadIcon from '../assets/images/app-store-dowload-icon.png'
import GooglePlayDowloadIcon from '../assets/images/google-play-dowload-icon.png'
import PhysicalActivity from '../assets/images/physical-activity.png'
//Videos
import NutritionVideo from '../assets/videos/nutrition.mp4'
import WheelLifeVideo from '../assets/videos/wheel-life.mp4'
//Components
import FAQToggle from '../components/FAQToggle'
import Reviews from '../components/Reviews'
import VideoPhoneMask from '../components/VideoPhoneMask'
//Data
import home from '../assets/translations/es/home.json'
//Utils
import {
    APP_STORE_CONVIDA_APP_URL,
    COMMENT_CONVIDA_URL,
    GOOGLE_PLAY_CONVIDA_APP_URL,
    redirectStoresDowloadApp,
} from '../utilities/general'
//styles
import 'react-multi-carousel/lib/styles.css'

const Home = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    }

    const handleNavigation = (url) => {
        window.open(url, '_blank')
    }

    return (
        <>
            <section className="hero is-white is-fullheight first-section-bg">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-vcentered">
                            <div className="column">
                                <div className="explanation-section">
                                    <p className="title-explanation-section mb-20">
                                        Tu mejor versión al alcance de un click
                                    </p>
                                    <p className="description-explanation-section mb-20">
                                        Sin largas filas, sin listas de
                                        espera... ¡potencia tu salud sin salir
                                        de casa! ConVida es la nueva aplicación
                                        de salud integral que te ayudará a
                                        alcanzar tu mejor versión hasta hoy.
                                    </p>
                                    <p className="mb-20">
                                        <button
                                            className="button is-primary-button-cv"
                                            onClick={redirectStoresDowloadApp}
                                        >
                                            Descargar APP
                                        </button>
                                    </p>
                                </div>
                            </div>
                            <div className="column">
                                <figure className="image is-1by1">
                                    <img
                                        className="img-convida"
                                        src={MobilePhone1}
                                        alt="Img ConVida app"
                                    />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="hero is-white is-fullheight second-section-bg"
                id="nutrition"
            >
                <div className="hero-body">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-flex is-justify-content-center is-align-items-center">
                                <VideoPhoneMask video={NutritionVideo} />
                            </div>
                            <div className="column">
                                <div className="explanation-section">
                                    <p className="main-title-explanation-section">
                                        Nutrición
                                    </p>
                                    <p
                                        className="title-explanation-section mb-20"
                                        style={{ fontSize: 36 }}
                                    >
                                        Un estilo de vida saludable comienza con
                                        una alimentación que asegure tu calidad
                                        de vida
                                    </p>
                                    <p className="description-explanation-section mb-20">
                                        Obtén tu plan de alimentación
                                        personalizado al alcance de un click.
                                        Además, ideas de menú y cientos de
                                        recetas saludables y deliciosas que te
                                        facilitarán cumplir tus metas.
                                    </p>
                                    <p className="mb-20">
                                        <button
                                            className="button is-primary-button-cv"
                                            onClick={redirectStoresDowloadApp}
                                        >
                                            Quiero mi plan de alimentación
                                            personalizado
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="hero is-white is-fullheight"
                id="physical-activity"
            >
                <div className="hero-body">
                    <div className="container gradient-container-convida">
                        <div className="columns">
                            <div className="column">
                                <div className="explanation-section">
                                    <p className="main-title-explanation-section">
                                        Actividad Física
                                    </p>
                                    <p
                                        className="title-explanation-section mb-20"
                                        style={{ fontSize: 36 }}
                                    >
                                        Mantenerte activo te dará energía para
                                        conquistar tus metas.
                                    </p>
                                    <p className="description-explanation-section mb-20">
                                        Mejora tu bienestar integral a través
                                        del ejercicio y el movimiento, sin
                                        equipo y sin necesidad de salir de casa.
                                    </p>
                                    <p className="mb-20">
                                        <button
                                            className="button is-primary-button-cv"
                                            onClick={redirectStoresDowloadApp}
                                        >
                                            Quiero iniciar mi plan de ejercicios
                                            hoy
                                        </button>
                                    </p>
                                </div>
                            </div>
                            <div className="column">
                                <figure className="image is-1by1">
                                    <img
                                        className="img-convida"
                                        src={PhysicalActivity}
                                        alt=""
                                    />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section
                className="hero is-white is-fullheight third-section-bg"
                id="emotional-health"
            >
                <div className="hero-body">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-flex is-justify-content-center is-align-items-center">
                                <VideoPhoneMask video={WheelLifeVideo} />
                            </div>
                            <div className="column">
                                <div className="explanation-section">
                                    <p className="main-title-explanation-section">
                                        Salud Emocional
                                    </p>
                                    <p
                                        className="title-explanation-section mb-20"
                                        style={{ fontSize: 36 }}
                                    >
                                        Una vida saludable solo puede existir
                                        con una mente sana
                                    </p>
                                    <p className="description-explanation-section mb-20">
                                        El poder de tus emociones es más valioso
                                        de lo que imaginas, te damos las
                                        herramientas para potenciarlo.
                                    </p>
                                    <p className="mb-20">
                                        <button
                                            className="button is-primary-button-cv"
                                            onClick={redirectStoresDowloadApp}
                                        >
                                            Quiero alcanzar mi máximo potencial
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="hero is-white is-fullheight">
                <div className="hero-body is-justify-content-center">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <div className="explanation-review-section container">
                                    <p className="title-section mb-20">
                                        Lo que nuestros usuarios dicen
                                    </p>

                                    <Carousel
                                        arrows={false}
                                        swipeable={true}
                                        draggable={true}
                                        showDots={true}
                                        responsive={responsive}
                                        containerClass="carousel-container"
                                        infinite={false}
                                        autoPlaySpeed={2000}
                                        keyBoardControl={true}
                                        customTransition="all .5"
                                        transitionDuration={500}
                                        dotListClass="custom-dot-list-style"
                                        itemClass="carousel-item-padding-40-px"
                                    >
                                        {home.userReviews.map((item, index) => (
                                            <Reviews item={item} key={index} />
                                        ))}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="hero is-white is-fullheight">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-vcentered">
                            <div className="column">
                                <div className="explanation-section">
                                    <p className="main-title-explanation-section">
                                        ¿Quiénes somos?
                                    </p>
                                    <p
                                        className="title-explanation-section mb-20"
                                        style={{ fontSize: 36 }}
                                    >
                                        Equipo ConVida
                                    </p>
                                    <p className="description-explanation-section mb-20">
                                        Somos un grupo de profesionales de la
                                        salud con formación en nutrición,
                                        psicología y movimiento humano;
                                        integrando nuestra experiencia logramos
                                        desarrollar una solución tecnológica
                                        capaz de mejorar el bienestar y la
                                        calidad de vida de nuestros usuarios.
                                        ¿Nuestro objetivo? Que alcances tu mejor
                                        versión.
                                    </p>
                                    <p className="mb-20">
                                        <button
                                            className="button is-primary-button-cv"
                                            onClick={redirectStoresDowloadApp}
                                        >
                                            Quiero ser mi mejor versión
                                        </button>
                                    </p>
                                </div>
                            </div>
                            <div className="column">
                                <figure className="image is-1by1">
                                    <img
                                        className="img-convida"
                                        src={ConvidaTeamIcon}
                                        alt="Equipo ConVida"
                                    />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="hero is-white is-fullheight">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <div className="explanation-section">
                                    <p className="title-section mb-20">
                                        Preguntas frecuentes
                                    </p>
                                    {home.faqs.map(
                                        ({ question, response }, index) => (
                                            <FAQToggle
                                                index={index}
                                                title={question}
                                                description={response}
                                                key={index}
                                            />
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="hero is-white is-fullheight">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-vcentered">
                            <div className="column">
                                <div className="explanation-section">
                                    <p
                                        className="title-explanation-section mb-20"
                                        style={{ fontSize: 36 }}
                                    >
                                        ¡Quiero alcanzar la mejor versión de mí
                                        hoy!
                                    </p>
                                    <p className="description-condition-terms-of-service mb-20">
                                        El mejor día para empezar a potenciar tu
                                        salud es hoy. No esperes más, obtén
                                        todas las herramientas que necesitas
                                        para un mejor estilo de vida en un solo
                                        lugar. Toca abajo y descarga ConVida
                                        App.
                                    </p>
                                    <div className="is-flex is-flex-direction-row is-flex-wrap-wrap">
                                        <a
                                            href={APP_STORE_CONVIDA_APP_URL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <figure className="image stores-icon mr-5">
                                                <img
                                                    src={AppStoreDowloadIcon}
                                                    alt="App store icon"
                                                />
                                            </figure>
                                        </a>
                                        <a
                                            href={GOOGLE_PLAY_CONVIDA_APP_URL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <figure className="image stores-icon">
                                                <img
                                                    src={GooglePlayDowloadIcon}
                                                    alt="Google play icon"
                                                />
                                            </figure>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <figure className="image is-1by1">
                                    <img
                                        className="img-convida"
                                        src={MobilePhone3}
                                        alt="Img mejor versión"
                                    />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="hero">
                <div className="hero-body review-section">
                    <p className="title has-text-white review-section__title">
                        Danos tu opinión por medio de nuestro formulario
                    </p>
                    <p className="subtitle has-text-white review-section__description">
                        Nos mantenemos al tanto de tus comentarios y sugerencias
                        para el beneficio de todos nuestros usuarios.
                    </p>
                    <p className="subtitle has-text-white review-section__description">
                        Nos encantaría que seas parte de la mejora continua.
                    </p>

                    <button
                        className="button review-section__button is-align-self-center"
                        onClick={() => handleNavigation(COMMENT_CONVIDA_URL)}
                    >
                        Dejar comentarios
                    </button>
                </div>
            </section>
        </>
    )
}

export default Home
