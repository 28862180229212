const ENV = 'PRO' //PRO,DEV
let ENDPOINT_URL = ''

switch (ENV) {
    case 'PRO':
        //Production
        ENDPOINT_URL = 'https://api.convida.co.cr/graphql'
        break
    case 'DEV':
        //Development
        ENDPOINT_URL = 'http://192.168.10.189:4000/graphql'
        break
    default:
        break
}
export { ENDPOINT_URL }
