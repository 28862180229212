const APP_STORE_CONVIDA_APP_URL =
    'https://apps.apple.com/us/app/convida/id1635652026'
const GOOGLE_PLAY_CONVIDA_APP_URL =
    'https://play.google.com/store/apps/details?id=fit.convida'

const COMMENT_CONVIDA_URL =
    'https://docs.google.com/forms/d/12zsGnpxtEeyA-cI5ULg7CPfsUgI7yefeyWsJi7t-DeY/viewform?edit_requested=true'

const SOCIAL_MEDIA = {
    instagram: {
        img: require('../assets/images/instagram-icon.png'),
        url: 'https://www.instagram.com/convidaapp/',
        color: '#E81F76',
        title: 'Instragram',
    },
    linkedIn: {
        img: require('../assets/images/linkedin-icon.png'),
        url: 'https://www.linkedin.com/company/convidaapp/ ',
        color: '#0077B5',
        title: 'LinkedIn',
    },
    tiktok: {
        img: require('../assets/images/tiktok-icon.png'),
        url: 'https://www.tiktok.com/@convidaapp ',
        color: '#000000',
        title: 'TikTok',
    },
    facebook: {
        img: require('../assets/images/facebook-icon.png'),
        url: 'https://www.facebook.com/convidaapp',
        color: '#3E60AB',
        title: 'Facebook',
    },
}

const redirectStoresDowloadApp = (e) => {
    e.preventDefault()
    const isAppleDevice = navigator.userAgent.match(/(iPad|iPhone|iPod|Mac)/i)

    window.open(
        isAppleDevice ? APP_STORE_CONVIDA_APP_URL : GOOGLE_PLAY_CONVIDA_APP_URL,
        '_blank'
    )
}

export {
    redirectStoresDowloadApp,
    SOCIAL_MEDIA,
    APP_STORE_CONVIDA_APP_URL,
    GOOGLE_PLAY_CONVIDA_APP_URL,
    COMMENT_CONVIDA_URL,
}
