import { gql } from '@apollo/client'

const READ_DIET_PLAN = gql`
    query ReadDietPlan($readDietPlanInput: DietPlanInput!) {
        readDietPlan(input: $readDietPlanInput) {
            gramsCarbohydrates
            gramsProteins
            amountDairy
            gramsFats
            gramsProteins
            amountFats
            amountProteins
            amountFlours
            amountVegetables
            amountFruits
        }
    }
`

const READ_DIET_PLAN_LOGS = gql`
    query ReadDietPlanLogs {
        readDietPlanLogs {
            id
            energyCalculation
            diet
            status
            log
            createdAt
            updatedAt
        }
    }
`

export { READ_DIET_PLAN, READ_DIET_PLAN_LOGS }
