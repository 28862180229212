import React from 'react'
import QuotesIcon from '../assets/images/quotes-icon.png'
import GreenOutlineStarIcon from '../assets/images/green-outline-star-icon.png'
import GreenFullStarIcon from '../assets/images/green-full-star-icon.png'

const Reviews = ({ item }) => {
    const { userName, review, rating } = item

    return (
        <div className="card-container height-100">
            <h4 className="card-title">{userName}</h4>
            <img src={QuotesIcon} alt="quote icon" className="quote-icon" />
            <p className="card-description">{review}</p>
            <div>
                {[...Array(5)].map((star, index) => (
                    <img
                        key={index}
                        src={
                            rating > index
                                ? GreenFullStarIcon
                                : GreenOutlineStarIcon
                        }
                        alt="Star"
                        className="star-icon"
                    />
                ))}
            </div>
        </div>
    )
}

export default Reviews
