import React from 'react'
import dateFormat from 'dateformat'
import { useQuery } from '@apollo/client'
import { READ_DIET_PLAN_LOGS } from '../api/Diet'

const DietPlanLogs = () => {
    const { loading, error, data } = useQuery(READ_DIET_PLAN_LOGS)

    console.log(data)

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`

    return (
        <div className="container p-5">
            <h1 className="title">Bitácora de cálculo de planes</h1>
            <div className="table-container">
                <table className="table is-fullwidth">
                    <thead>
                        <tr>
                            <th align="center">Id</th>
                            <th align="center">Dieta</th>
                            <th align="center">Cálculo energético</th>
                            <th align="center">Estado</th>
                            <th align="center">Gr. Carbohidratos</th>
                            <th align="center">Gr. Proteínas</th>
                            <th align="center">Gr. Grasa</th>
                            <th align="center">Porc. Lácteos</th>
                            <th align="center">Porc. Frutas</th>
                            <th align="center">Porc. Vegetales</th>
                            <th align="center">Porc. Harinas</th>
                            <th align="center">Porc. Proteínas</th>
                            <th align="center">Porc. Grasas</th>
                            <th align="center">Fecha creación</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.readDietPlanLogs.map((currentLog) => {
                            const {
                                gramsCarbohydrates,
                                gramsProteins,
                                gramsFats,
                                amountDairy,
                                amountFruits,
                                amountVegetables,
                                amountFlours,
                                amountProteins,
                                amountFats,
                            } = JSON.parse(currentLog.log)
                            return (
                                <tr key={currentLog.id}>
                                    <th align="center">{currentLog.id}</th>
                                    <td align="center">{currentLog.diet}</td>
                                    <td align="center">
                                        {currentLog.energyCalculation}
                                    </td>
                                    <td align="center">
                                        {currentLog.status === 'SUCCESS'
                                            ? 'Exitoso'
                                            : 'Fallido'}
                                    </td>
                                    <td align="center">{gramsCarbohydrates}</td>
                                    <td align="center">{gramsProteins}</td>
                                    <td align="center">{gramsFats}</td>
                                    <td align="center">{amountDairy}</td>
                                    <td align="center">{amountFruits}</td>
                                    <td align="center">{amountVegetables}</td>
                                    <td align="center">{amountFlours}</td>
                                    <td align="center">{amountProteins}</td>
                                    <td align="center">{amountFats}</td>
                                    <td
                                        align="center"
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        {dateFormat(
                                            currentLog.createdAt,
                                            'dd-mm-yyyy'
                                        )}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
} //DietPlanLogs

export default DietPlanLogs
